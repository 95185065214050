#root {
  height: 100vh;
  overflow-y: hidden;
}

.open-enrollment {
  height: 100vh !important;
  overflow-y: auto !important;
}

body {
  margin: 0;
}

#app-header {
  display: none !important;
}

#AtlasContentWrapper {
  background-color: #e8eef4 !important;
  background-image: url(/images/atlas-store.jpg);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.MuiList-root {
  padding-top: 0 !important;
}

.MuiCircularProgress-root {
  color: white !important;
}

.MuiListItem-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
